@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

/* INDEX CSS */

// helix-test
@import 'variables';

* {
	margin: 0;
	padding: 0;
	outline: 0;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
}

a {
	text-decoration: none;
	color: inherit;

	&:hover {
		color: inherit;
	}
}

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	overflow-y: hidden;
	color: $primary-text;
}

div[role="tooltip"] {
	border: 1px solid #dee2e6;
	border-radius: 3px;
	top: -60px !important;
	left: unset !important;
	right: 300px !important;
	box-shadow: 5px 10px 20px #888888;
	transform: translate3d(270px, 245px, 0px) !important;
}

div[role="tooltip"] .MuiDataGridFilterForm-root {
	padding: 10px !important;
}

div[role="tooltip"] .MuiDataGridFilterForm-columnSelect,
div[role="tooltip"] .MuiDataGridFilterForm-operatorSelect,
div[role="tooltip"] .MuiDataGridFilterForm-filterValueInput {
	padding: 0px 5px !important;
}

div[role="tooltip"] .MuiInput-underline:before {
	border: 0 !important;
}

div[role="tooltip"] .MuiDataGridFilterForm-columnSelect select,
div[role="tooltip"] .MuiDataGridFilterForm-operatorSelect select,
div[role="tooltip"] .MuiDataGridFilterForm-filterValueInput input {
	padding: 5px !important;
	font-size: 0.8125rem !important;
	border: 1px solid #dee2e6 !important;
}

div[role="tooltip"] .MuiDataGridFilterForm-columnSelect>.MuiInputLabel-formControl,
div[role="tooltip"] .MuiDataGridFilterForm-operatorSelect>.MuiInputLabel-formControl,
div[role="tooltip"] .MuiDataGridFilterForm-filterValueInput>.MuiTextField-root>.MuiInputLabel-formControl {
	font-size: 1rem !important;
	padding: 0px 5px !important;
}

.MuiIconButton-root:hover {
	background-color: transparent !important;
}

.MuiList-root {
	.MuiButtonBase-root {
		.MuiSvgIcon-root {
			width: 0.90rem;
			height: 0.90rem;
		}
	}
}

.MuiFormHelperText-contained {
	margin: 0 !important;
	padding: 5px 0px !important;
}

.MuiFormHelperText-root {
	font-size: 0.80rem;
}

.helperText {
	color: #ed5565;
	font-size: 0.80rem;
	font-weight: 400;
	padding: 5px 0px;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
	border-color: #c4c4c4 !important;
}

.MuiInputBase-input {
	font-size: 1rem !important;
}

@media print {
	@page {
		size: auto;
		margin: 20px;
	}
}